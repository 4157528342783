<template>
  <div class="liveCampaigns">
    <van-pull-refresh v-model="loading" @refresh="getList" pulling-text="Pull to refresh" loosing-text="Loose to refresh" loading-text="Loading...">
      <div class="container">

        <div v-if="allCampaignData.length">
          <div class="card" @click="gotoDetails(item)" v-for="(item, index) in allCampaignData" :key="item.campaign_id+index">

            <div v-if="item.status === 0" class="tag fill pending">Application Pending</div>
            <div v-if="item.status === 1" class="tag fill approved">Application Approved</div>
            <div v-if="item.status === 2" class="tag fill rejected">Application Rejected</div>

            <van-image :src="item.avatar" fit="cover" height="140" width="100%" />

            <div class="metadata">
              <van-row gutter="10">
                <van-col span="24">
                  <p class="title"><strong>{{ item.campaign_name }}</strong></p>
                </van-col>

                <!-- <van-col span="8" class="text-right">
                  <p><small>{{ item.start_date | days }}</small></p>
                  <p><small>{{ item.start_date | dateFormatMonth }} ~ {{ item.end_date | dateFormatMonth }}</small></p>
                </van-col> -->

                <van-col span="24">
                  <van-divider />
                </van-col>

              </van-row>

              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <img v-if="item.promo_platform === 1" src="@/assets/images/icon-youtube.svg" height="16" class="icon" />
                  <img v-if="item.promo_platform === 2" src="@/assets/images/icon-instagram.svg" height="16" class="icon" />
                </div>
                <div class="d-flex align-items-center">
                  <img src="@/assets/images/icon-announce.svg" height="16" class="icon" />
                  {{ item.campaign_type | campaignType }}
                </div>
                <div class="d-flex align-items-center">
                  <img src="@/assets/images/icon-rupee.svg" height="16" class="icon" />
                  {{ item.budget | formatNumber }}
                </div>
                <div class="d-flex align-items-center">
                  <img src="@/assets/images/icon-user-group.svg" height="11" class="icon" />
                  Min {{ item.min_follower | formatNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <van-empty description="No applied campaigns found!" v-if="!loading && !allCampaignData.length" image="https://influencer.soocel.com/img/no-campaign.f391a06e.png">
        </van-empty>

        <div v-if="loading && !allCampaignData.length">
          <div class="text-center" style="margin: 50px 0 0;">
            <van-loading color="#f54153" />
          </div>
        </div>

      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getappliedList } from '@/api/campaign'
import constantData from '@/utils/constant'

export default {
  name: 'Dashboard',
  data () {
    return {
      activeTab: 0,
      influencerstatus: constantData.influencerstatus,
      params: {
        page: 1,
        size: 10
      },
      totalPages: 0,
      loading: true,
      allCampaignData: [],
      myCampaignData: [],
      channelDialogue: false,
      campData: {},
      channelIds: [],
      mediaId: [],
      totalApprovedCampaign: 0,
      summery: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      this.loading = true
      const data = await getappliedList(this.params)
      if (data.success) {
        this.allCampaignData = []
        this.allCampaignData = data.campaigns.map(item => {
          item.category = item.category ? item.category.split(',') : []
          item.language = item.language ? item.language.split(',') : []
          item.gender = item.gender ? item.gender.split(',') : []
          return item
        })
        this.totalPages = Math.ceil(data.total_count / 10)
      } else {
        if (window.Toaster) {
          window.Toaster.postMessage(data.message)
        }
        this.allCampaignData = []
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },

    async getappliedList () {
      this.loading = true
      const data = await getappliedList(this.params)
      if (data.success) {
        this.myCampaignData = []
        this.myCampaignData = data.campaigns.map(item => {
          item.category = item.category ? item.category.split(',') : []
          item.language = item.language ? item.language.split(',') : []
          item.gender = item.gender ? item.gender.split(',') : []
          return item
        })
        const total = data.campaigns.filter(row => row.status === 1)
        this.totalApprovedCampaign = total.length
        this.summery = data.summery
        this.totalPages = Math.ceil(data.total_count / 10)
      } else {
        this.$snackbar({
          message: data.message
        })
        this.myCampaignData = []
      }
      this.loading = false
    },

    gotoDetails (campaign) {
      if (window.GoToUrl) {
        // window.GoDetails.postMessage(JSON.stringify({ campaignId: campaign.campaign_id, isApply: campaign.is_apply, id: campaign.id }))
        const url = `${window.location.origin}/campaign/details/${campaign.campaign_id}`
        window.GoToUrl.postMessage(JSON.stringify({ url: url, title: 'Campaign Details' }))
      } else {
        this.$router.push(`/campaign/details/${campaign.campaign_id}`)
      }
    }
  }
}
</script>

<style scoped>
  .about {
    background-color: #fafafa;
    min-height: 100vh;
  }
  .container {
    min-height: 100vh;
  }

  h4 {
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  /* Card */
  .card {
    padding-bottom: 5px;
  }
  .card >>> .van-image {
    margin: 0 0 8px;
  }
  .van-image, .van-image >>> img {
    border-radius: 14px 14px 0px 0px;
  }
  .van-divider {
    margin: 10px 0;
  }
  .card p {
    margin: 0 0 5px;
    color: #464646;
  }
  .card p.title {
    margin: 10px 0 0;
    text-transform: capitalize;
  }
  .card p strong {
    font-weight: 500;
  }
  .card .metadata {
    font-size: 12px;
    padding: 0 15px 10px 15px;
  }
  .card .metadata p {
    font-size: 14px;
    margin: 0 0 0;
  }
  .card .metadata p small {
    opacity: 0.5;
  }
  .icon {
    margin: 0 5px 0 0;
  }
  .tag {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
  }
</style>
